import AutoNumeric from 'autonumeric';
export class NumericInput {
  constructor(_at_self) {
      this.self = _at_self;
      this.input = this.self[0];
      this.init();
  }

  init() {
      new AutoNumeric(this.input, {
          digitGroupSeparator: ' '
      });
  }
}
