import {ErrorsRenderer} from './errors_renderer';
import {toggleClickSelectors} from "./common_methods";

class RemoteHelper {
	constructor(self) {
		this.replaceForm = this.replaceForm.bind(this);
		this.self = self;
		if (!this.errorsRenderer) {
			this.errorsRenderer = new ErrorsRenderer(this.self);
		}
		this.method = this.self.data('method'); // "post", "put" or "delete".
		this.loginModal = $('#login-modal');
		this.url = this.self.data('url'); // Provide ajax call on url
		this.params = this.self.data('params'); // Additional parameters for for url call
		this.confirm = this.self.data('confirm'); // Call confirm() before click or submit
		this.disableWith = this.self.data('disable-with'); // For submit-buttons and links
		this.externalSubmits = document.querySelectorAll(`button[data-submit='${this.self.attr('id')}']:not([form])`);
		this.init();
	}

	init() {
		this.self.off('ajax:beforeSend').on('ajax:beforeSend', e => {
			const [xhr, options] = Array.from(e.detail);
			this.errorsRenderer.clearErrors();
		});

		this.self.off('ajax:send').on('ajax:send', e => {
			toggleClickSelectors();
			const [xhr] = Array.from(e.detail);
		});

		this.self.off('ajax:stopped').on('ajax:stopped', () => {
		});

		this.self.off('ajax:success').on('ajax:success', e => {
			const [response, status, xhr] = Array.from(e.detail);

			if (this.self.data('turbolinks') && response instanceof HTMLDocument) {
				// this.replaceForm(xhr.responseText);
				const errors = $(xhr.responseText).find('form').data('errors');
				if (errors) {
					this.errorsRenderer.render({full_messages: errors}, true);
				}
			}
		});

		$(this.self).off('ajax:error').on('ajax:error', (e) => {
			const [response, status, xhr] = Array.from(e.detail);
			// WORKAROUND: response and status are empty
			const status_code = xhr.status;
			switch (status_code) {
				case 422:
					this.errorsRenderer.render(response);
					break;
				default:
					console.warn(response);
			}
		});

		this.self.off('ajax:complete').on('ajax:complete', e => {
			const [xhr, status] = Array.from(e.detail);
			toggleClickSelectors(true);
		});

		this.self.off('custom:clearFormErrors').on('custom:clearFormErrors', e => {
			this.errorsRenderer.clearErrors();
		});

		// return addEventListenerToCollection(this.externalSubmits, 'click', function() {
		//     toggleClickSelectors(false);
		//     submitByUjs(this.dataset.submit);
		// });
	}

	replaceForm(responseText) {
		const newNode = $('<div id="content">').append(responseText);
		$('#content').replaceWithCallback(newNode, function () {
			runOnDemandInitializers('ajax form replace', this);
			return $(document).trigger('DOMContentLoaded');
		});
	}
}

export {RemoteHelper};