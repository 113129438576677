import {throttle} from './common_methods'

export class JsMap {
  constructor(_at_self) {
    this.self = _at_self;
    this.modal = this.self;
    this.mapId = 'map';
    this.fieldSet = void 0;
    this.latitudeInp = void 0;
    this.longitudeInp = void 0;
    this.provider = void 0;
    this.searchField = $('#map-search');
    this.init();
  }

  init = function() {
    this.modal.on('show.bs.modal', (function(_this) {
      return function(e) {
        _this.setLocationFld(e);
        return _this.initProvider();
      };
    })(this));
    this.modal.on('shown.bs.modal', (function(_this) {
      return function(e) {
        return _this.provider.focusLocation();
      };
    })(this));
    this.modal.on('hide.bs.modal', (function(_this) {
      return function(e) {
        return _this.purgeProvider();
      };
    })(this));
    this.modal.on('hidden.bs.modal', (function(_this) {
      return function(e) {
        return _this.searchField.val('');
      };
    })(this));
    this.searchField.on('input', (function(_this) {
      return function(e) {
        throttle(function() {
          if (e.target.value.length > 0) {
            return _this.locateAddress(e.target.value);
          }
        });
      };
    })(this));
  };

  setLocationFld = function(e) {
    this.fieldSet = $(e.relatedTarget).parents('[data-provider]');
    this.latitudeInp = this.fieldSet.find('[data-latitude]');
    this.longitudeInp = this.fieldSet.find('[data-longitude]');
  };

  initProvider = function() {
    var provider;
    provider = this.providerClass(this.fieldSet);
    this.provider = new provider(this);
  };

  getPoint = function() {
    return [this.latitudeInp.val(), this.longitudeInp.val()];
  };

  providerClass = function($field) {
    var className;
    className = $field.data('provider').camelize();
    return window[className + 'Map'];
  };

  purgeProvider = function() {
    this.provider.purge();
    this.provider = void 0;
    $("#" + this.mapId).replaceWith($('<div>', {
      id: this.mapId,
      style: 'width: 100%; height: 100%; overflow: hidden; margin:0;'
    }));
  };

  locateAddress = function(quey) {
    this.provider.locateAddress(quey);
  };

  locationSelectCallback = function(_arg, addrComps) {
    var lat, lng;
    lat = _arg[0], lng = _arg[1];
    $.each(addrComps, (function(_this) {
      return function(key, value) {
        _this.fieldSet.find("input[data-component='" + key + "']").val(value);
      };
    })(this));
    this.latitudeInp.val(lat);
    this.longitudeInp.val(lng);
    this.modal.modal('hide');
  };
}