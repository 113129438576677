class GridFormer {
    constructor(self) {
        this.self = self;
        this.sidebarToggler = $('button[data-collapse="sidebar"]');
        this.sidebarMenu = this.self.find('.sidebar');
        this.collapseTogglers = this.self.find('a[data-toggle=collapse]');
        this.collapsibles = this.self.find('.collapsible');
        this.init();
    }

    init () {
        var state;
        this.self.toggleClass('sidebar--fixed', sessionStorage['sidebarFixedState'] === 'true');
        state = sessionStorage['sidebarFixedState'] === 'true';
        this.collapsibles.filter(sessionStorage['sidebarExpandedMenu']).toggleClass('show', state);
        this.collapseTogglers.on('click', (function(_this) {
            return function(e) {
                var collapsible;
                collapsible = $($(e.currentTarget).attr('href'));
                if (collapsible.hasClass('show')) {
                    sessionStorage['sidebarExpandedMenu'] = 'empty';
                } else {
                    sessionStorage['sidebarExpandedMenu'] = $(e.currentTarget).attr('href');
                }
            };
        })(this));
        this.sidebarToggler.on('click', (function(_this) {
            return function() {
                var hasFixedClass;
                _this.self.toggleClass('sidebar--fixed');
                hasFixedClass = _this.self.hasClass('sidebar--fixed');
                sessionStorage['sidebarFixedState'] = hasFixedClass;
                if (!hasFixedClass) {
                    _this.collapseMenu();
                }
            };
        })(this));
        this.sidebarMenu.mouseleave((function(_this) {
            return function() {
                if (!_this.self.hasClass('sidebar--fixed')) {
                    _this.collapseMenu();
                }
            };
        })(this));
    };

    collapseMenu() {
        this.collapsibles.collapse('hide');
    };

}

export {GridFormer};
