import consumer from "./consumer"

if (window.unread_subscription) {
    window.unread_subscription.unsubscribe()
    consumer.subscriptions.remove(window.unread_subscription);
}
const unreadMessagesChannel = consumer.subscriptions.create(
    { channel: "UnreadMessagesChannel", user_id: document.querySelector('head').dataset.userId },
    {
        connect() {
            console.log('Connected')
        },
        received(data) {
            console.log("Received data.");
            if (data.sum_unread_count > 0) {
                $('#new_messages a').removeClass('d-none');
                $('#new_messages a span.icon-text').text(data.sum_unread_count)
                $('head title').text('У вас ' + data.sum_unread_count + ' новых сообщений!')
            } else {
                $('#new_messages a').addClass('d-none');
                $('head title').text('Hong Kong Atlant Group')
            }
            Object.keys(data.chats).forEach(chat_id => {
                $(`.chat_unread_count[data-chat-id=${chat_id}]`).text(data.chats[chat_id])
                let container = $(`.chat_unread_count[data-chat-id=${chat_id}]`).closest('.unread_text')
                if (data.chats[chat_id] > 0) {
                    container.removeClass('d-none')
                } else {
                    container.addClass('d-none')
                }
            });
        }
    }
)
window.unread_subscription = unreadMessagesChannel;

export default unreadMessagesChannel;
